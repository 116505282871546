import { useEffect, useMemo, useState } from 'react'
import {
  BackboneShelves,
  Button,
  ButtonStatus,
  Column,
  Container,
  Row,
  ShelfCarouselAlvi,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  useEvents,
  useMobile,
  useUpdateFromShelfAlvi,
  useUserAlvi
} from '@smu-chile/pkg-unimarc-hooks'
import { IAlviCoupon } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlviCoupon'
import { getCouponProducts } from '@smu-chile/pkg-unimarc-hooks/services/getCouponsProducts'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsByIdentifier'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import {
  useProductsCarousel,
  useShelvesData
} from 'components/ProductsCarousel/helpers/useProductsCarousel'
import { statusPromotions } from 'shared/helpers'
import { CouponDetailHeader } from '../CouponDetailHeader/CouponDetailHeader'
import { CouponDetailLegal } from '../CouponDetailLegal/CouponDetailLegal'
import { CouponDetailMessage } from '../CouponDetailMessage/CouponDetailMessage'
import { CouponDetailProducts } from '../CouponDetailProducts/CouponDetailProducts'
import styles from './CouponDetailContent.module.css'

const CouponLabelMap: Record<string, string> = {
  unactived: 'Activa tu cupón',
  actived: 'Agregar',
  used: 'Usado'
}

const CouponButtonStatusMap: Record<string, ButtonStatus | null> = {
  unactived: 'disabled',
  actived: 'initial',
  used: 'disabled'
}
export interface CouponDetailContentProps {
  activeLoading?: boolean
  coupon?: IAlviCoupon
  isLoggedIn?: boolean
  onActive?: () => void
  onShelfCatch?: (payload: unknown) => void
}

export const CouponDetailContent = ({
  activeLoading = false,
  coupon,
  isLoggedIn,
  onActive,
  onShelfCatch
}: CouponDetailContentProps) => {
  const site = 'alvi'
  const [errorProducts, setErrorProducts] = useState(false)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [productsResponse, setProductsResponse] = useState<Data>(null)

  const { typeOffer, status } = coupon

  const buttonLabel = CouponLabelMap[status]
  const isTotalTicket = typeOffer == '1'
  const buttonStatus = isOrderFormLoading
    ? 'loading'
    : CouponButtonStatusMap[status]
  const couponStatus = status === 'unactived'

  useEffect(() => {
    fetchProducts()
  }, [coupon?.id])

  const products = useMemo(() => {
    if (productsResponse && productsResponse.availableProducts) {
      return [
        ...(productsResponse.availableProducts || []),
        ...(productsResponse.notAvailableProducts || [])
      ].map((product: Product) => {
        const { isPromotion, offer, ppum, price } = statusPromotions(product)

        const { detailUrl, images, sellers } = product

        const { inOffer, listPrice, ppumListPrice } = sellers[0]

        const urlName = formatProductUrl(detailUrl)
        return {
          ...product,
          likeListButtons: false,
          img: images[0],
          inOffer,
          isPromotion,
          listPrice,
          offer,
          ppum: `(${ppum})`,
          ppumListPrice: `(${ppumListPrice})`,
          price,
          title: product?.name,
          urlName
        }
      })
    }

    return []
  }, [productsResponse])

  const {
    editTempOrderForm,
    handleAdd,
    handleOnClick,
    handleRemove,
    shelvesData
  } = useUpdateFromShelfAlvi({ products, isOrderFormLoading })

  const shelvesDataMapped = shelvesData.map((item) => {
    const hasStock = item.sellers[0].availableQuantity > 0
    if (!hasStock) item.buttonStatus = 'disabled'
    if (couponStatus && hasStock) item.buttonStatus = 'initial'
    return item
  })

  const { isMobile } = useMobile()
  const { data: user } = useUserAlvi()
  const validationStatus = user?.validationStatus ?? 'logout'

  const { dataPromotions } = useProductsCarousel({ isMobile })
  const productsMemo = useShelvesData(
    shelvesDataMapped,
    dataPromotions,
    isMobile
  )

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const fetchProducts = async () => {
    try {
      setErrorProducts(false)
      setLoadingProducts(true)

      const response = await getCouponProducts(coupon.id)

      if (response) {
        setProductsResponse(response)
      } else {
        setProductsResponse(null)
      }
    } catch (e) {
      setErrorProducts(true)
    }

    setLoadingProducts(false)
  }

  const handleCouponErrorReload = () => {
    fetchProducts()
  }

  const handleCouponStampActive = () => {
    if (onActive) {
      onActive()
    }
  }

  const handleQuantityAdd = (payload: { itemId: string }) => {
    if (status === 'actived') {
      handleAdd(payload)
    }
  }

  const handleQuantityClick = (payload: { itemId: string }) => {
    if (status === 'unactived') {
      handleCouponStampActive()
    }

    if (status === 'actived') {
      handleOnClick(payload)
    }
  }

  const handleQuantityRemove = (payload: { itemId: string }) => {
    if (status === 'actived') {
      handleRemove(payload)
    }
  }

  const handleShelfCarouselCatch = (payload: unknown) => {
    if (onShelfCatch) {
      onShelfCatch(payload)
    }
  }

  return (
    <>
      <BigScreen>
        <Row alignItems='start'>
          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            Detalle del cupón
          </Text>
        </Row>
      </BigScreen>

      <Row padding='24px 0'>
        <CouponDetailHeader
          activeLoading={activeLoading}
          coupon={coupon}
          onActive={handleCouponStampActive}
        />
      </Row>

      {loadingProducts && (
        <Container padding='11px 0'>
          <BackboneShelves
            shelvesQuantityDesktop={5}
            shelvesQuantityMobile={4}
          />
        </Container>
      )}

      {errorProducts && !loadingProducts && (
        <Row>
          <Column
            alignItems='center'
            justifyContent='center'
            minHeight='40vh'
          >
            <CouponDetailMessage
              action={
                <Button
                  borderRadius='24px'
                  fontWeight={getGlobalStyle('--font-weight-semibold')}
                  label='Volver a cargar'
                  onClick={handleCouponErrorReload}
                  site={site}
                  size='small'
                />
              }
              errorType='loadingProducts'
              subtitle='Inténtalo nuevamente más tarde. '
              title='Lo sentimos, no hemos podido cargar tus productos.'
            />
          </Column>
        </Row>
      )}

      {!loadingProducts &&
        !errorProducts &&
        !isTotalTicket &&
        shelvesData &&
        shelvesData.length < 1 && (
          <Row justifyContent='center'>
            <Column maxWidth='600px'>
              <CouponDetailMessage
                errorType='noProducts'
                subtitle={
                  <>
                    Puedes usar este cupón para compras en todas&nbsp;
                    <BigScreen>
                      <Text
                        fontSize='xl'
                        type='b'
                      >
                        nuestras tiendas.
                      </Text>
                    </BigScreen>
                    <SmallScreen>
                      <Text
                        fontSize='sm'
                        isFlex={false}
                        type='b'
                      >
                        nuestras tiendas.
                      </Text>
                    </SmallScreen>
                  </>
                }
                title='Lo sentimos, no hay productos disponibles para este cupón en la web.'
              />
            </Column>
          </Row>
        )}

      {!loadingProducts && shelvesData && shelvesData.length > 0 && (
        <Row width='100%'>
          <BigScreen>
            <ShelfCarouselAlvi
              buttonLabel={buttonLabel}
              carouselProps={{
                showDots: false,
                autoPlay: false,
                shouldResetAutoplay: false
              }}
              catchShelfRef={handleShelfCarouselCatch}
              couponState={couponStatus}
              customButtonGroupProps={{ customClass: styles.arrows }}
              handleOnClick={handleQuantityClick}
              isLoggedIn={isLoggedIn}
              isMobile={false}
              items={productsMemo}
              itemsToShow={4}
              quantityButtonProps={{
                handleAdd: handleQuantityAdd,
                handleChange: null,
                handleRemove: handleQuantityRemove
              }}
              quantityButtonStyles={{
                maxHeight: '35px',
                maxWidth: '100%',
                minWidth: '220px',
                sizeCircle: 25
              }}
              showArrows
              validationStatus={validationStatus}
            />
          </BigScreen>

          <SmallScreen>
            <CouponDetailProducts
              buttonLabel={buttonLabel}
              buttonStatus={buttonStatus}
              couponState={couponStatus}
              isLoading={isOrderFormLoading}
              isLoggedIn={isLoggedIn}
              items={shelvesData}
              onClickAdd={handleQuantityAdd}
              onClickItem={handleQuantityClick}
              onClickRemove={handleQuantityRemove}
              validationStatus={validationStatus}
            />
          </SmallScreen>
        </Row>
      )}

      {!errorProducts && (
        <Row>
          <Column padding='24px 8px'>
            <CouponDetailLegal couponLegal={coupon?.legal} />
          </Column>
        </Row>
      )}
    </>
  )
}
