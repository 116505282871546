import {
  Button,
  Column,
  Container,
  Picture,
  Text,
  Row,
  ButtonStatus,
  IconNames
} from '@smu-chile/pkg-unimarc-components'
import Image from 'next/future/image'
import { IAlviCoupon } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlviCoupon'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { formatDate } from '@smu-chile/pkg-unimarc-hooks'

import { CouponDetailHeaderCenter } from './CouponDetailHeaderCenter/CouponDetailHeaderCenter'
import { CouponDetailHeaderStamp } from './CouponDetailHeaderStamp/CouponDetailHeaderStamp'

const COUPON_DEFAULT_IMAGE = process?.env?.NEXT_PUBLIC_COUPON_DEFAULT_IMAGE
const UNIPAY_IMAGE_URL = process?.env?.NEXT_PUBLIC_COUPON_UNIPAY_IMAGE

const CouponButtonBackgroundColorMap: Record<string, string> = {
  actived: getGlobalStyle('--color-alvi-primary-aqua-marine')
}

const CouponButtonColorMap: Record<string, string> = {
  actived: getGlobalStyle('--color-alvi-primary-blue3')
}

const couponButtonBorderMap: Record<string, string> = {
  actived: 'none'
}

const couponButtonIconMap: Record<string, string> = {
  actived: 'CheckBorder'
}

export const CouponChannelColorMap: Record<string, string> = {
  all: '',
  'Solo App': getGlobalStyle('--color-alvi-background-selago'),
  'Solo Tienda': getGlobalStyle('--color-alvi-background-selago')
}

const CouponGrayscaleMap: Record<string, boolean> = {
  unactived: false,
  actived: false,
  used: true
}

const CouponLabelMap: Record<string, string> = {
  unactived: 'Activar cupón',
  actived: 'Cupón activado',
  used: 'Cupón utilizado'
}

const CouponStatusMap: Record<string, ButtonStatus> = {
  unactived: 'initial',
  actived: 'initial',
  used: 'disabled',
  loading: 'loading'
}

export interface CouponDetailHeaderProps {
  active?: boolean
  activeLoading?: boolean
  coupon?: IAlviCoupon
  onActive?: () => void
}

export const CouponDetailHeader = ({
  activeLoading,
  coupon,
  onActive
}: CouponDetailHeaderProps) => {
  const {
    countUsed,
    couponImage: image,
    expirationDate,
    maximumUsed,
    status,
    channel,
    description,
    lead,
    maximumUnits,
    subtitle,
    title,
    unipay
  } = coupon

  const couponButtonColor = CouponButtonColorMap[status]
  const couponButtonBackgroundColor = CouponButtonBackgroundColorMap[status]
  const couponButtonBorder = couponButtonBorderMap[status]
  const couponButtonIcon = couponButtonIconMap[status]
  const couponChannelColor = CouponChannelColorMap[channel ?? 'all']
  const couponChannelLabel = channel ?? 'all'
  const couponImage = image !== '' ? image : COUPON_DEFAULT_IMAGE
  const couponGrayscale = CouponGrayscaleMap[status]
  const couponLabel = activeLoading ? 'Activando cupón' : CouponLabelMap[status]
  const couponStatus = CouponStatusMap[activeLoading ? 'loading' : status]

  const handleActive = () => {
    onActive && status === 'unactived' && onActive()
  }

  return (
    <CouponDetailHeaderStamp>
      <Container>
        <Row alignItems='center'>
          <BigScreen>
            <Column width='100px'>
              <Picture
                grayscale={couponGrayscale}
                height='100'
                nextImage={Image}
                src={couponImage}
                width='100'
              />
            </Column>
          </BigScreen>

          <CouponDetailHeaderCenter>
            <BigScreen>
              <Row>
                {couponChannelLabel != 'all' && (
                  <Container
                    backgroundColor={couponChannelColor}
                    borderRadius={'4px'}
                    padding='0 8px'
                    width={'auto'}
                  >
                    <Text
                      color='blue'
                      fontSize='xs'
                      lineHeight='1.5'
                    >
                      {couponChannelLabel}
                    </Text>
                  </Container>
                )}

                {unipay && (
                  <Container
                    alignItems='center'
                    backgroundColor={getGlobalStyle(
                      '--color-campaigns-turquoise'
                    )}
                    borderRadius='4px'
                    margin='0 8px'
                    padding='0 8px'
                    width='auto'
                    zIndex='1'
                  >
                    <Text
                      color='black'
                      fontSize='xs'
                      lineHeight='1.5'
                    >
                      Exclusivo
                    </Text>

                    <Container margin='0 0 -5px 4px'>
                      <Picture
                        height='9'
                        nextImage={Image}
                        src={UNIPAY_IMAGE_URL}
                        width='30'
                      />
                    </Container>
                  </Container>
                )}
              </Row>
            </BigScreen>

            <Text
              fontSize='xl'
              fontWeight='semibold'
              lineHeight='1.75'
            >
              {title}
            </Text>

            <Text
              color='gray'
              fontSize='sm'
            >
              {subtitle} {lead ? `(${lead})` : ''}
            </Text>

            <Text
              fontSize='md'
              fontWeight='semibold'
            >
              {description}
            </Text>

            <Column padding='4px 0 0'>
              <Text fontSize='sm'>Máximo {maximumUnits} por cliente</Text>

              <Text fontSize='sm'>
                Expira el: {formatDate(expirationDate)} - Has usado {countUsed}/
                {maximumUsed} canjes
              </Text>
            </Column>

            <SmallScreen>
              <Container padding='8px 0 0'>
                <Button
                  background={couponButtonBackgroundColor}
                  border={couponButtonBorder}
                  borderRadius='24px'
                  color={couponButtonColor}
                  fontSize='14px'
                  fontWeight='500'
                  label={couponLabel}
                  minWidth='160px'
                  onClick={handleActive}
                  prevIcon={couponButtonIcon as IconNames}
                  prevIconColor={getGlobalStyle('--color-alvi-primary-blue3')}
                  site='alvi'
                  size='small'
                  status={couponStatus}
                  type='coupons'
                />
              </Container>
            </SmallScreen>
          </CouponDetailHeaderCenter>

          <SmallScreen>
            <Column
              alignItems='center'
              justifyContent='center'
              margin='0 0 0 16px'
              width='100px'
            >
              {unipay && (
                <Container
                  alignItems='center'
                  backgroundColor={getGlobalStyle(
                    '--color-campaigns-turquoise'
                  )}
                  borderRadius='4px'
                  margin='8px 0'
                  padding='0 8px'
                  width='auto'
                  zIndex='1'
                >
                  <Text
                    color='black'
                    fontSize='xs'
                    lineHeight='1.5'
                  >
                    Exclusivo
                  </Text>

                  <Container margin='0 0 -5px 4px'>
                    <Picture
                      height='9'
                      nextImage={Image}
                      src={UNIPAY_IMAGE_URL}
                      width='30'
                    />
                  </Container>
                </Container>
              )}

              <Container margin='0 0 8px'>
                <Picture
                  grayscale={couponGrayscale}
                  height='100'
                  nextImage={Image}
                  src={couponImage}
                  width='100'
                />
              </Container>

              {couponChannelLabel != 'all' && (
                <Container
                  alignItems='center'
                  backgroundColor={couponChannelColor}
                  borderRadius='4px'
                  margin='0 auto'
                  padding='0 8px'
                  width='auto'
                >
                  <Text
                    color='blue'
                    fontSize='xs'
                    lineHeight='1.5'
                  >
                    {couponChannelLabel}
                  </Text>
                </Container>
              )}
            </Column>
          </SmallScreen>

          <BigScreen>
            <Column
              alignItems='end'
              margin='auto'
            >
              <Button
                background={couponButtonBackgroundColor}
                border={couponButtonBorder}
                borderRadius='24px'
                color={couponButtonColor}
                fontWeight='500'
                label={couponLabel}
                onClick={handleActive}
                prevIcon={couponButtonIcon as IconNames}
                prevIconColor={getGlobalStyle('--color-alvi-primary-blue3')}
                site='alvi'
                status={couponStatus}
                type='coupons'
              />
            </Column>
          </BigScreen>
        </Row>
      </Container>
    </CouponDetailHeaderStamp>
  )
}
